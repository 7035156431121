import React, { Component } from "react"
import Container from "../helpers/Container.js"
import Answers from "./Answers.js"

export default class Question extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onAnswerSelect = e => {
    this.props.onAnswerSelect(e)
  }

  render () {
    const { question, id } = this.props
    return (
      <Container className="container-xg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mt-2r">
              <div dangerouslySetInnerHTML={{ 
                  __html: question.questionStatement
                }}
              />
              { question.answers.map((node, index) => (
                  <Answers
                    answer={node}
                    type={question.type}
                    id={`${id}`}
                    index={index}
                    onAnswerSelect={this.onAnswerSelect}
                    key={`${id}${index}]`}
                  />
                ))
              }
              
            </div>
          </div>
        </div>
      </Container>
    )
  }
}