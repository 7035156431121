import React, { Component } from "react"
import Container from "../helpers/Container.js"

export default class QuestionnaireProgress extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }
 
  render () {
    const { total, current } = this.props
    const perc = (current / total).toFixed(2)*100;
    return (
      <Container className="container-xg">
        <div className="bg-color-gray-3 round mb-2r">
          <div className="tac c-white bg-color-purple round-left progress" style={{width: `${perc}%`}}>
            {perc}%
          </div>
        </div> 
      </Container>
    )
  }
}