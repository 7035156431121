import React, { Component } from "react"
import Container from "../helpers/Container.js"
import Questions from "./Questions.js"
import Diagnosis from "./Diagnosis.js"
import QuestionnaireProgress from "./QuestionnaireProgress.js"

export default class Questionnaire extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      inPre: new Array(this.props.data.questionnaire.length).fill(true),
      diagnosis: new Array(this.props.data.questionnaire.length),
      isTheEnd: false,
      totalAnswered: 0
    }
  }

  removeInPre = () => {
    let { inPre, currentIndex } = this.state
    inPre[currentIndex] = false
    this.setState( { inPre } )
  }

  changeIndex = e => {
    let { currentIndex, inPre, diagnosis, totalAnswered } = this.state
    const { data } = this.props;

    diagnosis[currentIndex] = e;
    this.setState( { diagnosis, totalAnswered:  totalAnswered + e.answered } )

    if(currentIndex === (data.questionnaire.length-1)) {
      this.setState( {isTheEnd : true} )
    } else {
      if(typeof data.questionnaire[currentIndex+1].pre === 'undefined') {
        inPre[currentIndex+1] = false
        this.setState( { inPre: inPre, currentIndex : currentIndex + 1} )
      } else {
        this.setState( {currentIndex : currentIndex + 1} )
      }
    }
  }

  render () {
    const { data, email, nome } = this.props
    const questionnaire = data.questionnaire
    const { currentIndex, inPre, isTheEnd, diagnosis, totalAnswered } = this.state
    return (
      <Container className="container-xg">
        <div className="container-fluid">
          <div className="row jc-c">
            <div className="col col-lg-8 col-md-10">
              { inPre[currentIndex] &&
                <div>
                  <div dangerouslySetInnerHTML={{ 
                      __html: questionnaire[currentIndex].pre
                    }}
                  />
                  <button className="btn bg-color-purple mt-2r" onClick={this.removeInPre}>
                    Iniciar teste
                  </button>
                </div>
              }
              { !inPre[currentIndex] && !isTheEnd &&
                <div>
                  <QuestionnaireProgress total={data.totalQuestions} current={totalAnswered} />
                  <Questions
                    title={questionnaire[currentIndex].title}
                    description={questionnaire[currentIndex].description}
                    questions={questionnaire[currentIndex].questions} 
                    diagnosis={questionnaire[currentIndex].diagnosis}
                    finishedQuestions={this.changeIndex}
                    hasNext={currentIndex < questionnaire.length}
                    key={currentIndex}
                  />
                </div>
              }
            </div>
            <div className="col-lg-12">
              { isTheEnd && 
                <Diagnosis diagnosis={diagnosis} results={data.diagnosis} email={email} nome={nome} />
              }
            </div>
          </div>
        </div>
      </Container>
    )
  }
}