import React, { Component } from "react"
import Container from "../../common/helpers/Container.js"
import PageGIC from "../../common/elements/PageGIC.js"
import Questionnaire from "../../common/elements/Questionnaire.js"
import { getCookie } from "../../common/lib/useCookie.js"

export default class IndiceDeIntegracaoDaComunicacao extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      loading: false,
      data: null,
      email: null,
      nome: null
    }
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  componentDidMount() {
    var isFormFilled = getCookie('gic-filled');
    let search = window.location.search.replace('?', '').split('&')
    let searchArr = []
    search.forEach(element => {
      searchArr = searchArr.concat(element.split('='))
    });
    // this.setState( { email : 'fernandocandiani@gmail.com', nome: 'Fernando' } )
    // this.fetchData()
    if(isFormFilled && searchArr.includes('email') && searchArr.includes('nome')) {
      let keyEmail = parseInt(this.getKeyByValue(searchArr, 'email'))
      let keyNome = parseInt(this.getKeyByValue(searchArr, 'nome'))
      this.setState( { email : searchArr[keyEmail+1], nome: searchArr[keyNome+1] } )
      this.fetchData()
    } else {
      if (typeof window !== undefined) {
        window.location.pathname = '/grau-de-integracao-da-comunicacao'
      }
    }
  }

  fetchData = () => {
    this.setState({ loading: true })
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () =>  {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        this.setState({ loading: false, data: JSON.parse(xhr.response) });
      } else if (xhr.readyState === XMLHttpRequest.DONE) {
        this.setState({ loading: false });
      }
    };
    xhr.open("GET", `https://backoffice.comunicacaointegrada.com.br/wp-content/uploads/questions.json`, true);
    // xhr.open("GET", `https://backoffice.comunicacaointegrada.com.br/wp-content/uploads/questions_test.json`, true);
    xhr.send();
  }

  render () {
    const { loading, data, email, nome } = this.state
    return (
      <PageGIC>
        <Container className="container-xg mt-4r mb-4r exam-container">
          <div className="container-fluid">
            <div className="col-lg-12">
              <div className="row">
                { loading &&
                  <div className="tac">
                    <span className="spinner large c-purple sending" role="status" aria-hidden="true"></span>
                  </div>
                }
                { data && !loading &&
                  <Questionnaire data={data} email={email} nome={nome} />
                }
              </div>
            </div>
          </div>
        </Container>
      </PageGIC>
    )
  }
}