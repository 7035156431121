import React, { Component } from "react"
import Container from "../helpers/Container.js"
import Question from "./Question"

export default class Questions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      answers: new Array(this.props.questions.length),
      submitting: false
    }
  }  

  onAnswerSelect = e => {
    let { answers } = this.state
    answers[e.id] = e;
    this.setState( { answers } )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitting: true });
    
    const { answers } = this.state;
    let correctAnswers = 0;
    answers.forEach(el => {
      if(el.answer.isCorrect) correctAnswers++
    })
    let percentage = (correctAnswers / answers.length) * 100;
    
    const { diagnosis, questions } = this.props;
    let i = 0;
    for(; i < diagnosis.length; i++) {
      if(diagnosis[i].percentage >= percentage) {
        break;
      }
    }
    this.props.finishedQuestions( {
      title: this.props.title,
      description: this.props.description,
      text: diagnosis[i].text,
      percentage: diagnosis[i].percentage,
      relatedContent: diagnosis[i].relatedContent,
      correctAnswers: correctAnswers,
      answered: questions.length
    })
  }

  render () {
    const { questions, title, description } = this.props
    const { submitting } = this.state
    return (
      <Container className="container-xg">
        <div dangerouslySetInnerHTML={{ 
            __html: title
          }}
        />
        <div dangerouslySetInnerHTML={{ 
            __html: description
          }}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={this.handleSubmit}>
                { questions && 
                  questions.map((node, index) => (
                    <Question
                      question={node}
                      id={index}
                      onAnswerSelect={this.onAnswerSelect}
                      key={index}
                    />
                  ))
                }
                <button type="submit" className={`btn bg-color-purple mt-2r ${submitting ? "sending" : ""}`}>
                  <span className="spinner" role="status" aria-hidden="true"></span>
                  <span className="label">Continuar</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}