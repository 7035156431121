import React, { Component } from "react"
import Container from "../helpers/Container.js"

export default class Diagnosis extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount()
  {
    this.sendData()
  }

  sendData = () => {
    var xhr, data = new FormData();

    if (window.XMLHttpRequest) {
      xhr = new XMLHttpRequest();
    } else {
      // xhr = new ActiveXObject("Microsoft.XMLHTTP");
    }

    data.append("action", "comunicacao_generate_pdf");
    data.append("diagnosis", `${JSON.stringify(this.props.diagnosis)}`);
    data.append("results", JSON.stringify(this.props.results));
    data.append("email", this.props.email);
    data.append("nome", this.props.nome);

    xhr.onreadystatechange = () =>  {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
      }
    };
    xhr.open("POST", "https://backoffice.comunicacaointegrada.com.br/wp-admin/admin-ajax.php", true);
    xhr.send(data)
    // xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // xhr.setRequestHeader('Accept', '*/*');
    // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    // xhr.send(JSON.stringify({
    //   "action": "comunicacao_generate_pdf",
    //   "diagnosis": this.props.diagnosis,
    //   "results": this.props.results,
    //   "email": this.props.email
    // }));
  }
 
  renderMainDiagnostic() {
    const { results, diagnosis } = this.props;

    let correctAnswers = 0;
    diagnosis.forEach(el => {
      correctAnswers += el.correctAnswers;
    })

    let i = 0;
    for(; i < results.ranges.length; i++) {
      if(results.ranges[i].number > correctAnswers) {
        break;
      }
    }
    i--;
    if(i < 0) i = 0;

    return (
      <div>
        <div dangerouslySetInnerHTML={{ 
            __html: results.title
          }}
        />
        <div className="tac">
          <img src={results.ranges[i].image} width='300px' alt="Indicador do seu resultado" />
        </div>
        <div dangerouslySetInnerHTML={{ 
            __html: results.ranges[i].title
          }}
        />
        <div dangerouslySetInnerHTML={{ 
            __html: results.ranges[i].description
          }}
        />
        <div dangerouslySetInnerHTML={{ 
            __html: results.postText
          }}
        />
      </div>
    );
  }

  render () {
    // const { diagnosis } = this.props
    return (
      <Container className="container-xg">
        <div className="container-fluid">
          <div className="row">
            { this.renderMainDiagnostic() }
            {/* { diagnosis.map((node, index) => (
                <div key={index}>
                  <div dangerouslySetInnerHTML={{ 
                      __html: node.title
                    }}
                  />
                  <div dangerouslySetInnerHTML={{ 
                      __html: node.text
                    }}
                  />
                  <div dangerouslySetInnerHTML={{ 
                      __html: node.relatedContent
                    }}
                  />
                </div>
              ))
            } */}
          </div>
        </div>
      </Container>
    )
  }
}