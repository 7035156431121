import React, { Component } from "react"
import Container from "../helpers/Container.js"

export default class Answers extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
    this.onAnswerSelect.bind(this);
  }

  onAnswerSelect = e => {
    this.props.onAnswerSelect( {
      id: this.props.id,
      index: this.props.index,
      answer: this.props.answer,
    })
  }
 
  render () {
    const { answer, type, id, index } = this.props
    return (
      <Container className="container-xg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mt-05r" onChange={this.onAnswerSelect}>
              {type === 'radio' &&
                <input
                  className="form-check-input mt-015r"
                  type="radio"
                  name={id}
                  id={`${id}-${index}`}
                  value={answer.label}
                  required
                /> 
              } 
              <label className="ml-025r" htmlFor={`${id}-${index}`}>
                { answer.label }
              </label>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}